.slider {
    position: relative;
    height: 100%;
}

.slides {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    text-align: left;
    font-size: 6vh;
    font-weight: 700;
    color: #00f85f;
    padding-left: 20px;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 45px;
    color: white;
    z-index: 1;
    cursor: pointer;

    &Right{
        @extend .arrow;
        right: 32px;
    }

    &Left{
        @extend .arrow;
        left: 32px;
    }
}