.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider_container {
    width: 100%;
    height: 500px;
    margin: 0;
}