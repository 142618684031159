.container {
    height: 100%;
    width: 100%;
    background-color: #1976d2;
    text-align: center;
    padding: 50px 0;
    position: relative;
    display: flex;
    flex-direction: column;
}

.title {
    color: white;
    padding-top: 0px;
    padding-bottom: 40px;
}

.text {
    color: white;
    font-size: 3rem;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;

    &Author{
        @extend .text;
    }

}

.reviewArrow {
    position: absolute;
    top: 90%;
    transform: translate(0, -50%);
    font-size: 45px;
    color: white;
    z-index: 1;
    cursor: pointer;

    &Right{
        @extend .reviewArrow;
        right: 32px;
    }

    &Left{
        @extend .reviewArrow;
        left: 32px;
    }
}
